
import { defineComponent, onMounted, onUpdated } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { document } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { Testata } from '@/models/testata.model';

addIcons({
  document: document,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonFabButton,
  IonFab,
  modalController,
  IonIcon,
  IonCardSubtitle,
  IonImg,
  IonLabel,
} from '@ionic/vue';

export default defineComponent({
  name: 'JnAreaTematicaCard',
  props: {
    testata: {
      type: Testata,
      required: true,
    },
  },
  components: {
    IonCard,
    //IonCardHeader,
    //IonCardSubtitle,
    IonCardTitle,
    //IonLabel,
    /* IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuToggle,
    IonTitle,
    IonMenuButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonText,*/
    IonImg,
  },

  setup(props) {
    const router = useRouter();

    const goToAreaTematica = async () => {
      router.push('/main/aree-tematiche/' + props.testata.id);
    };

    return {
      props,
      goToAreaTematica,
    };
  },
});
