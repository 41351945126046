<template>
  <ion-page>
    <ion-header mode="md">
      <ion-toolbar> </ion-toolbar>
    </ion-header>

    <ion-content fullscreen mode="md">
      <ion-grid>
        <ion-row>
          <ion-col
            v-for="t of testate"
            :key="t"
            size="12"
            size-md="6"
            size-lg="4"
            class="ion-no-padding"
          >
            <jn-area-tematica-card :testata="t" />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUpdated, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { document } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import JnAreaTematicaCard from '../../components/AreaTematicaCard.vue';
import { Testata } from '@/models/testata.model';
import { AreeTematiche } from '@/models/enums.model';
import { JnService } from '@/services/jn.service';

addIcons({
  document: document,
});

import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/vue';

export default defineComponent({
  name: 'JnAreeTematiche',
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    JnAreaTematicaCard,
    IonGrid,
    IonRow,
    IonCol,
  },

  setup() {
    const store = useStore();
    const router = useRouter();

    const testate = ref([] as Testata[]);

    onMounted(async () => {
      for (const at of AreeTematiche) {
        testate.value.push(JnService.getTestata(at));
      }
    });

    return {
      testate,
    };
  },
});
</script>
